<template> 
  <div>
  <app-menu/>
  <v-container class="grey lighten-5" style="min-height:756px;"  >
    <div class="text-center">
      <v-dialog v-model="isLoad" persistent width="300">
         <v-card color="primary" dark >
          <v-card-text>
            cargando
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card> 
      </v-dialog>
      <!-- //:::::::::::: Alerta error -->
      <div>
          <v-alert
            v-model="alert"
            dismissible
            color="error"
            border="left"
            elevation="24"
            colored-border
            icon="mdi-alert"
          >
            <strong>{{msgError}}</strong>
          </v-alert>
        </div>
        <!-- //::::::::::: alerta success -->
        <div>
          <v-alert
            v-model="alert2"
            dismissible
            color="success"
            border="left"
            elevation="24"
            colored-border
            icon="mdi-check-all"
          >
            <strong>{{msgError}}</strong>
          </v-alert>
        </div>
      <v-dialog  v-model="dialogdiasentrega"  width="800"> 
        <v-card>
          <v-card-title style="background:#004996;color:#fff;">HORARIOS DISPONIBLES</v-card-title> 
          <v-card-text>
            <v-radio-group > 
              <v-card dark color="#385F73" class="mx-auto">
                <v-card-title style="font-size:14px">
                  {{sucursal.name}}
                </v-card-title>
                 <center style="font-size:14px">
                  No. Orden: {{purchase.documentno}}
                </center>
                <br>
              </v-card>
              <v-list>  
                <v-radio-group>  
                  <v-list-group v-for="dia in diasEntrega" :key="dia.fecha">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-card color="transparent">{{dia.valor}}</v-card>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template> 
                      <v-list-item v-for="dia in diasEntrega" :key="dia.fecha">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-radio :label="dia.valor" :value="dia" ></v-radio> 
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                  </v-list-group>
                </v-radio-group> 
              </v-list>  
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider> 
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogdiasentrega = false" >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
    <template >
      <v-tabs >
        <v-tab>Compras ({{purchasesLength}}) - P&aacute;gina {{page}}</v-tab> 
      </v-tabs>
    </template>
    <template>
      <br>
      
    </template>
    <!-- <v-card-title >
        <v-text-field v-model="search"  prepend-icon="mdi-package-variant" label="Buscar " single-line hide-details></v-text-field>
    </v-card-title> -->
    <v-container class="my-12" v-if="purchasesLength > 0" >
      <template>
        <v-row>
          <v-col cols="12" md="5" style="margin:0 auto;">
            <v-text-field v-model="buscar" label="Buscar orden de venta"></v-text-field>
          </v-col>
          <v-col cols="12" md="2" style="margin:0 auto;">
            <v-select 
                prepend-icon="mdi-shopping-outline"
                :items="[10,15,20,45]"
                label="Compras a Mostrar"
                outlined
                @change="paginator()"
                v-model="totalPage" 
            ></v-select> 
          </v-col>

        </v-row>
        <v-item-group multiple >
            <v-container>
              <p v-if="verfiltro == true && valorBuscado != ''" style="font-size: 2em;color :#909090">  
              <strong>{{valorBuscado}}</strong> 
              <!-- <v-btn text color="error" @click="deleteFilter">X</v-btn> -->
            </p>
            <v-row v-for="purchase in filteredList" :key="purchase._id" >
                <v-col >
                <v-item >
                    <v-card  class="d-flex align-center"  min-height="100">  
                      <div class="my-5 flex-grow-1 text-center">
                        <v-container class="grey lighten-5">
                        <v-row style="font-size: 0.9em;color :#909090">
                            <v-col  cols="5" md="4">
                                <div>Orden de Venta</div>
                                <div style="color :#000" ><strong>{{purchase.documentno}}</strong></div>
                            </v-col>
 
                            <v-col v-if="purchase.status_pay=='pagado' || purchase.method_pay == 'CRE'" cols="7" sm="4">
                                <v-btn text color="primary" @click="acuse(purchase)">
                                    Imprimir orden
                                </v-btn>
                            </v-col>
                            <!-- <v-col v-if="purchase.method_pay == 'TRA' && purchase.requiresdate==true" cols="7" sm="4">
                                <v-btn depressed color="primary" @click="asignarfechaRecoleccion(purchase)">
                                  Fecha Entrega
                                </v-btn>
                            </v-col>  -->
                            <v-col  cols="6" sm="4">
                              <!-- <div>Fecha programada para la entrega</div> 
                              <div style="color :#000" v-if="purchase.method_pay == 'TRA' &&
                                (purchase.requiresdate==true || purchase.status_pay == 'pendiente')">
                                <div style="color :red">Aún no asignada</div>
                              </div>
                              <div style="color :#000" v-else>
                                {{formatDate(purchase.fechaprometida)}}
                              </div> -->
                              <!-- {{purchase.fechaprometida}} -->

                              <div>Fecha disponible para la entrega</div>  
                              <div style="color :#000" v-if="purchase.fechaprometida == undefined">
                                Disponible hasta confirmar tu pago 
                              </div>
                              <div v-else style="color :#000">
                                {{formatDate(purchase.fechaprometida)}} a partir de las {{purchase.horaprometida}}:00 hrs.
                              </div>

                            </v-col>
                          
                          

                            <v-col cols="6" sm="4">
                                <div>Método de pago</div> 
                                <div style="color :#000">
                                    {{buscarTipoPago(purchase.method_pay)}} 
                                     <!-- ({{purchase.estado_pago}}) -->
                                </div>
                            </v-col> 
                            <v-col cols="6" sm="4">
                                <div>Estado del pago</div> 
                                <div style="color :red" v-if="purchase.status_pay=='Cancelado'"> 
                                  {{purchase.status_pay=="pendiente"?"Pendiente": (purchase.status_pay=="pagado"?"Pagado": purchase.status_pay)}} 
                                </div>
                                <div style="color :#000" v-else> 
                                  {{purchase.status_pay=="pendiente"?"Pendiente": (purchase.status_pay=="pagado"?"Pagado": purchase.status_pay)}} 
                                </div>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <div>Total</div>
                                <v-row>
                                    <v-col style="color :#000"><strong>{{formatMXN(purchase.grandtotal)}}</strong></v-col>
                                </v-row> 
                            </v-col>
                            <v-col  cols="6" sm="4">
                                <div>Entrega</div> 
                                <div style="color :#000"> 
                                  {{purchase.status_entrega=="pendiente"?"Pendiente": 
                                  (purchase.status_entrega=="entregado"?"Entregado": purchase.status_entrega)}} 

                                </div>
                            </v-col>
                            <v-col  cols="6" sm="4">
                                <div>Fecha Compra</div>
                                <div style="color :#000">{{formatDate(purchase.created_at)}} {{formatTime(purchase.created_at)}}</div>
                            </v-col> 
                            
                            <!-- <v-col  cols="6" sm="4">
                                <div>Entrega en</div>
                                <div style="color :#000">{{buscaSucursal(purchase.ad_org_recpt_id)}}</div>
                            </v-col>   -->
                            <v-col  cols="6" sm="4">
                                <div>Entrega en</div>
                                <div style="color :#000">{{buscaSucursal(purchase.ad_org_recpt_id)}}</div>
                            </v-col>  
                            <!-- :::::::: Opiniones -->
                            <v-col v-if="purchase.status_pay == 'pagado' && purchase.documentno != '-'">
                              <!-- <div >¡Envíanos tus comentarios!</div> -->
                              <v-btn class="carga" text color="red" @click="modalComentario(purchase._id,purchase.documentno,purchase.ad_user_id,purchase.nombre_cliente)">¡Envíanos tus comentarios!</v-btn>
                              <!-- <a href="">¡Envíanos tus comentarios!</a> -->
                            </v-col>

                        </v-row>  
                        </v-container>
 

                        <!-- Muestra el panel si el pedido es por transferencia  LUIS-->
                        <v-expansion-panels v-if="purchase.direccion !=0" >
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <center>Dirección (Paquetería)</center>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content >
                              <v-row class="container justify-center">
                                <v-col cols="4">
                                  <v-card elevation="17" v-for="item in purchase.direccion " :key="item.c_location_id">
                                    <v-card max-height="50" dark color="blue lighten-1" style="border-radius:0px 0px 180px 0px;">
                                      <v-card-title class="justify-center" style="font-size:16px;">
                                        {{item.locationname.toUpperCase()}}
                                      </v-card-title>
                                    </v-card>
                                    <v-card-text >
                                        <div style="color :#000;">PAÍS: <strong>{{item.pais}}</strong></div>
                                        <div style="color :#000;">ESTADO: <strong>{{item.description}}</strong> </div>
                                        <div style="color :#000">COLONIA: <strong>{{item.colonia}}</strong> </div>
                                        <div style="color :#000">MUNICIPIO: <strong>{{item.municipio}}</strong> </div>
                                        <div style="color :#000">CALLE: <strong>{{item.calle}}</strong> </div>
                                        <div style="color :#000">REFERENCIA: <strong>{{item.referencia}}</strong> </div>
                                        <div style="color :#000">CÓDIGO POSTAL: <strong>{{item.postal}}</strong> </div>
                                        <div style="color :#000">NÚMERO: <strong>{{item.office_number}}</strong> </div>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                                <v-col cols="5">
                                  <div >
                                      <v-avatar class="ma-"   tile  size="200">
                                        <v-img   src="../../public/Usuario/world.png"></v-img>
                                      </v-avatar>
                                  </div>

                                </v-col>
                              </v-row>
                              <br>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <!-- Muestra el panel si el pedido es por transferencia  LUIS-->

                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <center>Productos</center>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                          

                              <v-row class="my-2">
                                  <v-col cols="12" md="4" v-for="producto in purchase.productos" :key="producto.value">
                                      <v-card class="mx-auto" max-width="500" outlined @click="seeProduct(producto.value)" > 
                                          <v-row >
                                              <v-col  cols="12" md="4">
                                                <v-img  width="150px" :src="`${ubicacionImagenProducto}${producto.value}.jpg`" :lazy-src="`../../public/noImg.png`"
                                                          aspect-ratio="1" class="grey lighten-2"> 
                                                    <template v-slot:placeholder>
                                                      <v-row class="fill-height ma-0" align="center" justify="center">
                                                        <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                                                      </v-row>
                                                    </template> 
                                                  </v-img> 
                                              </v-col>
                                              <v-col  cols="12" md="8">
                                                  <div v-if="producto.prodCompleto.data.length > 0" class="my-2" style="font-size: 0.8em;color :#909090">
                                                      {{producto.prodCompleto.data[0].name.substring(0,45) + "..."}}
                                                  </div>  
                                                  <v-row>
                                                      <v-col><div style="font-size: 0.8em;color :#909090">Total</div>{{formatMXN(producto.total)}} </v-col>
                                                      <v-col><div style="font-size: 0.8em;color :#909090">Cantidad</div>{{producto.quantity}} </v-col>
                                                  </v-row>
                                                      <v-row>
                                                          <v-col><div style="font-size: 0.8em;color :#909090">Código: {{producto.value}}</div></v-col>
                                                  </v-row>  
                                              </v-col>
                                          </v-row> 
                                      </v-card> 
                                  </v-col> 
                              </v-row> 

                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels> 
                        <v-expansion-panels v-if="purchase.status_pay!='Cancelado'">
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <center>Entregas</center>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>  

                              <template>
                                <v-row v-if="purchase.entregas.length > 0">
                                  <v-col cols="12" sm="4" v-for="entrega in purchase.entregas" :key="entrega.documentno">
                                      <v-chip class="ma-2" color="success" outlined>Entrega: {{entrega.documentno}}</v-chip>
                                      <v-stepper  vertical  v-if="entrega.estados.length > 0">
                                        <template v-for="(estado, index) in  entrega.estados" :step="(index+2).toString()">
                                          <v-stepper-step complete :step="(index+2).toString()" :key="'step-'+index+2"> 
                                            {{estado.name}}
                                            <small>{{formatDate(estado.datetrx)}}</small>
                                          </v-stepper-step> 
                                          <v-stepper-content :step="(index+2).toString()" :key="'cont-'+index+2"> 
                                          </v-stepper-content>
                                        </template>
                                      </v-stepper> 
                                      <v-stepper  v-else vertical >
                                        <template>
                                          <v-stepper-step complete step="1">
                                            Preparando Material
                                            <small>{{formatDate(purchase.created_at)}}</small>
                                          </v-stepper-step>
                                          <v-stepper-content step="1"> 
                                          </v-stepper-content>
                                        </template>
                                      </v-stepper> 
                                  </v-col>
                                </v-row>
                                <v-row v-else>
                                  <v-col>
                                    <v-stepper  vertical >
                                      <template>
                                        <v-stepper-step complete step="1">
                                          Preparando Material
                                          <small>{{formatDate(purchase.created_at)}}</small>
                                        </v-stepper-step>
                                        <v-stepper-content step="1"> 
                                        </v-stepper-content>
                                      </template>
                                    </v-stepper> 
                                  </v-col>
                                </v-row>
                              </template>

                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels> 
                      </div>   
                    </v-card>
                </v-item>
                </v-col>
            </v-row>
            </v-container>
        </v-item-group>
        <div class="text-center">
          <v-pagination
            color="blue"
            v-on:input="paginator" 
            v-model="page"
            :length="lengthPaginator"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
    </v-container>   

    <v-container v-if="purchasesLength == 0 && isLoad == false"  style="min-height:656px;"  >
      <div>
        <v-card style="min-height:186px;"> 
            <p class="text-center" style="font-size: 2em;color :#909090">  
              <br> 
             Aún no tienes compras.
            </p> 
        </v-card> 
      </div>  
    </v-container>
    <!-- ::::::::::DIALOGS -->
    <v-dialog v-model="opinion" width="30%" >
      <v-card >
        <v-card  dark color="blue lighten-1" style="border-radius:0px 0px 180px 0px;">
          <div class="d-flex justify-center">
            <div>
              <v-card-title class="text-h4" style="color:black;">ENVÍANOS </v-card-title>
              <v-card-subtitle style="color:white" class="text-h5">tus comentarios</v-card-subtitle>
            </div>
            <v-avatar  size="80" tile style="top:13px;" >
              <v-img  src="../../public/Usuario/comentario.png"></v-img>
            </v-avatar>
          </div>
        </v-card>
          <v-card-text>
            <br>
            <p>Recuerda que para nosotros es <strong>importante</strong> saber tus comentarios </p>
            <p>Nos ayudan a solucionar <strong> problemas</strong> y así poder brindarte una mejor 
            <strong> experiencia</strong> en <strong style="font-size:15px;"> Amoresens Distribuidores</strong>.</p>
            <v-row class="container">
              <v-col
              cols="12"
              md="12"
              ><v-textarea 
              label="Mensaje"
                v-model="comentario.mensaje"
                shaped
                auto-grow
                outlined></v-textarea>
              </v-col>
              <v-col cols="12">También puedes contactarnos:
                <v-row style="margin: 0 auto;">
                  <v-col md="2">
                    <v-img style="margin-left:18px;" max-width="30px" src="../../public/Usuario/gmail.gif"></v-img> 
                  </v-col>
                  <v-col md="10">
                    Al correo: <a href="https://mail.google.com/mail/u/0/?ogbl#inbox?compose=new">servicio.distribuidor@amoresens.com.mx</a>
                  </v-col>
                </v-row>
                <v-col cols="12" md="12" class="justify-center">
                  <center class="text-h5">
                    ¡ GRACIAS !
                    <!-- {{comentario}} -->

                  </center>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="opinion = false">Cancelar</v-btn>
            <v-btn text color="blue" @click="enviarOpinion()">Enviar</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container> 
  </div>
</template>


<style scoped>
    .centered-input >>> input {
      text-align: center
    }
    .carga{
    font-family:"Zen Kaku Gothic Antique", sans-serif;

    animation-name: parpadeo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name:parpadeo;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
  }
  @-moz-keyframes parpadeo{  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }

  @-webkit-keyframes parpadeo {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }

  @keyframes parpadeo {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
</style>

<script>
import config from '../json/config.json'
import axios from 'axios'; 
import AppMenu from '../components/Menu.vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import party from "party-js";
export default {
  name: "CrudDepartment",
  props: ['pagina'], 
  data() {
    return {
      ubicacionImagenProducto:'https://amoresens.com.mx/distribuidor/productos/',
      alert:false,
      alert2:false,
      opinion:false,
      buscar:'',
      page: 1,
      dialogdiasentrega : false,
      purchases : [],
      diasEntrega : [],
      isLogged : false,
      user : {},
      isLoad : false, 
      msgErro : "",
      sucursales:[],
      sucursal: {},
      purchase : {},
      lengthPaginator:0,
      totalPage:10,
      productosPaginator:[],
      valorBuscado : "",
      verfiltro : false,
      comentario:{},
      msgError:"",
      caseSensitive: false,
    }; 
  }, 
  async created() {
    try{
      if (parseInt(this.pagina) > 0) {
        this.page = parseInt(this.pagina);
      }else{
        this.page = 1;
      }
    }catch (ex){
      this.page = 1;
    }
    this.isLoad = true;
    await this.validaLogin();
    if (this.isLogged) {
      await this.allPurchases();
      await this.allSucursales();
      await this.paginator();
    }else{
      this.menu('/shop/Login/');  
    } 
    this.isLoad = false;  
  }
  ,components: { 
        'app-menu': AppMenu, 
  },
  methods: {
    async asignarfechaRecoleccion(compra){   
      this.purchase = compra;
      this.sucursal = {};
      for (let index = 0; index < this.sucursales.length; index++) {
          if(this.sucursales[index].ad_org_id == compra.ad_org_recpt_id) this.sucursal = this.sucursales[index];
      }
      this.diasEntrega = [];
      this.diasEntrega = await axios.get(config.apiAmoresens + "/sucursal/get_dias_entrega"
        ,{headers: { 'token': this.$cookie.get('token') },params: this.sucursal}
      ).then(res=>{
        return res.data.data;
      }).catch(err=>{return err;});
      this.dialogdiasentrega = true; 
    },
    buscarTipoPago(method_pay){
      let valur = "";
      switch (method_pay) {
        case "EFE":
          valur = "Pago en sucursal";
        break;
        case "CRE":
          valur = "Crédito";
        break;
        case "paypal":
          valur = "Paypal";
        break;
        case "TRA":
            valur = "Transferencia electrónica"
        break;
        default:
          valur = "No definido";
        break;
      } 
      return valur;
    }, 
    async acuse(data){
        console.log(data);
        this.isLoad = true;

        let paqueteria = data.ad_org_recpt_id;

        let bodyTBL= []; 
        let totl = (500 + (data.productos.length * 45));
        var doc = new jsPDF('p', 'pt',[500,totl ]);
        var img = new Image()
        img.src = '/Generales/logo_Amoresens.jpeg'; 
        // doc.addImage(img,'png', 150, 18, 0, 0);    
        doc.addImage(img,'png', 200, 0, 110, 110);   

        doc.setTextColor(0,106,164);
        doc.setFontSize(11);
        doc.text(40,100,`Estimado/a ${data.nombre_cliente}`);
        doc.text(175,115,`¡Muchas gracias por tu compra!`);
        doc.setTextColor(0,0,0);
        doc.setFontSize(15);
        doc.text(145,140,`Orden de Venta ${data.documentno}`);
        doc.setFontSize(10);
        doc.text(40,165,`Fecha de compra: ${this.formatDate(data.created_at)} ${this.formatTime(data.created_at)}`);
          if(paqueteria == "Paquetería" ){
          doc.text(40,185,`Envío por paquetería`);
          }else{
          doc.text(40,180,`Entrega disponible a partir del día: ${this.formatDate(data.fechaprometida)} a las ${data.horaprometida==undefined?0:data.horaprometida}:00 hrs`);
          doc.text(40,195,`Entrega en la Sucursal: ${this.buscaSucursal(data.ad_org_recpt_id)}`);
          }

        doc.text(40,210,`Método de pago: ${this.buscarTipoPago(data.method_pay)}`);
        let counPosition = 245;
        for (let index = 0; index < data.productos.length; index++) {
          const element = data.productos[index]; 
          let imgs = await axios.get(config.apiAmoresens + "/productos/imgByValue"
            ,{headers: { 'token': this.$cookie.get('token') },
            params: {filter: element.value}})
            .then(function (response) {  
              return response.data.data;
            }).catch(function (response){   
              return response;
            });
          if (imgs.length == 1) {
            imgs = imgs[0].img;
            imgs = 'data:image/jpeg;base64,' + btoa(
                new Uint8Array(imgs.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            ); 
            doc.addImage(imgs,'jpg', 35, counPosition, 40, 40); 
          }
          counPosition += 45; 
          bodyTBL.push([element.name,`${element.quantity} X ${this.formatMXN(element.price)}`,this.formatMXN(element.total)]);
        }  
        doc.autoTable({
          head: [['Producto' ,'Cantidad','Subtotal']],
          margin: { top: 225,left:80 }, 
          bodyStyles: {minCellHeight: 40},
          body: bodyTBL, 
        });
        let finalY = doc.lastAutoTable.finalY;
        doc.setFontSize(15);
        doc.text(180, finalY+20,'Total: ' + this.formatMXN(data.grandtotal));
        doc.setFontSize(10);
        doc.text(45, finalY+38,'Para cualquier duda o aclaración comunicate al télefono 55-8103-5976.');
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(doc.output("bloburl"), "_blank");   
        } else {
            doc.save(`Orden de venta ${data.documentno}.pdf`);
        }
        this.isLoad = false;
    },  
    async allSucursales(){ 
        this.sucursales = await axios.get(config.apiAmoresens + "/sucursal/get_auth", 
        {
          'headers': { 'token': this.$cookie.get('token') }
        }).then(res=>{return res.data.data;})
        .catch(err=>{return err;}); 
    },   
    buscaSucursal(idOrg){ 
      let nombreSucursal = "";
      this.sucursales.forEach(element => {
        if (element.ad_org_id == idOrg)nombreSucursal = element.name;
      });
      return nombreSucursal;
    },
    async mounted() {    
      window.scrollTo(0,0);
    },
    menu(path){
      if (this.$route.path !== path){
        this.$router.push(path);
      }  
    },
    async validaLogin(){
         this.user = await axios.get(config.apiAmoresens + "/user/userByToken", 
        {'headers': { 'token': this.$cookie.get('token') }})
        .then(res=>{return res.data;})
        .catch(err=>{return err;});    
        if (this.user.status == "success") {
          this.user = this.user.user; 
          this.isLogged = true;
        }else if(this.user.status == "unauthorized"){ 
          this.isLogged = false;
        }
    }
    ,async allPurchases(){
        let puchases = await axios.get(config.apiAmoresens + "/saleorder/get_auth", 
        {
          'headers': { 'token': this.$cookie.get('token') }
        }).then(res=>{return res.data;})
        .catch(err=>{return err;});   

        if (puchases.status == "success") {  
            this.purchases = puchases.data; 

            for (let index = 0; index < this.purchases.length; index++) {
               this.purchases[index].entregas = [];
              const purchase = this.purchases[index]; 
              if (purchase.status_pay =='pagado' || purchase.method_pay == 'CRE') {
                
                this.purchases[index].entregas = await axios.get(
                  config.apiAmoresens + "/seguimientopedido/entregas", 
                {
                  'headers': { 'token': this.$cookie.get('token') },
                  params: {code_entrega : purchase.documentno}
                }).then(res=>{return res.data;})
                .catch(err=>{console.log(err);return false;});   
                

                if (this.purchases[index].entregas.status == "success") { 
                  this.purchases[index].entregas = this.purchases[index].entregas.data;
 
                  // :::::::::::::::::::::::::: get estados
                  for (let i = 0; i < this.purchases[index].entregas.length; i++) { 
                    const entregas = this.purchases[index].entregas[i]; 
                    let estadosres = await axios.get(
                      config.apiAmoresens + "/seguimientopedido/estados", 
                    {
                      'headers': { 'token': this.$cookie.get('token') },
                      params: {m_inout_id : entregas.m_inout_id}
                    }).then(res=>{return res.data;})
                    .catch(err=>{console.log(err);return false;});    

                    if (estadosres.status == "success") {
                      this.purchases[index].entregas[i].estados = estadosres.data;  
                    }else{
                      this.purchases[index].entregas[i].estados = [];
                    } 
                  }
                  // :::::::::::::::::::::::::: get estados 
                }else{
                  this.purchases[index].entregas = [];
                }
              } 

            } 
        }else if(this.user.status == "unauthorized"){  
            this.menu('/shop/Login/');
        }
    } 
    ,seeProduct(value){ 
      this.$router.push('/shop/Product/'+value+"/purchases/0");
    }
    ,formatMXN(value) {
        var formatter = new Intl.NumberFormat('en-ES', {style: 'currency', currency: 'USD',});
        return `${formatter.format(value)} MXN`;
    },
    formatDate(dates) { 
        var month= ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio",
            "Agosto","Septiembre","Octubre","Noviembre","Diciembre"];  
        return `${(new Date(Date.parse(dates))).getDate()} de ${month[(new Date(Date.parse(dates))).getMonth()]} del ${(new Date(Date.parse(dates))).getFullYear()}`
    },
    formatTime(dates) { 
        const hours = ('0' + (new Date(Date.parse(dates))).getHours()).slice(-2)
        const minutes = ('0' + (new Date(Date.parse(dates))).getMinutes()).slice(-2)
        return `${hours}:${minutes}`
    },
    async paginator(){
      this.lengthPaginator = Math.ceil(this.purchases.length/this.totalPage);
        // console.log(this.lengthPaginator);
      // this.isLoad = true; 
      this.productosPaginator = [];
      let fin = ((this.page * (this.totalPage )) -1) > (this.purchases.length -1) ? (this.purchases.length -1):((this.page * (this.totalPage )) -1);  
    
      for (let index = ( ( (this.page -1) * this.totalPage)); index <= fin; index++) {
        let element = this.purchases[index];    
        this.productosPaginator.push(element);
        if (index == fin) {
          // this.isLoad = false;
          window.scrollTo(0,0);
        }
      } 
      window.scrollTo(0,0);
    },
    async search(){   
      // return this.purchases.filter(purchases=>{
      //   return purchases.documentno.toLowerCase().includes(this.buscar.toLowerCase());
      // })
      // this.isLoad = true;  
      this.valorBuscado = this.buscar.toUpperCase();
      await this.allPurchasesv2();
      this.page = 1;
      await this.paginator();
      this.verfiltro = true;
      // this.isLoad = false;  
    },
    async allPurchasesv2(){
      const params = {
        filter: this.valorBuscado
      };
      console.log(params);
        let puchases = await axios.get(config.apiAmoresens + "/saleorder/filtrador", 
        {
          'headers': { 'token': this.$cookie.get('token') },params:params
        }).then(res=>{return res.data;})
        .catch(err=>{return err;});   

        if (puchases.status == "success") {  
            this.purchases = puchases.data; 
            console.log(this.purchases,'dd');

            for (let index = 0; index < this.purchases.length; index++) {
               this.purchases[index].entregas = [];
              const purchase = this.purchases[index]; 
              if (purchase.status_pay =='pagado' || purchase.method_pay == 'CRE') {
                
                this.purchases[index].entregas = await axios.get(
                  config.apiAmoresens + "/seguimientopedido/entregas", 
                {
                  'headers': { 'token': this.$cookie.get('token') },
                  params: {code_entrega : purchase.documentno}
                }).then(res=>{return res.data;})
                .catch(err=>{console.log(err);return false;});   
                

                if (this.purchases[index].entregas.status == "success") { 
                  this.purchases[index].entregas = this.purchases[index].entregas.data;
 
                  // :::::::::::::::::::::::::: get estados
                  for (let i = 0; i < this.purchases[index].entregas.length; i++) { 
                    const entregas = this.purchases[index].entregas[i]; 
                    let estadosres = await axios.get(
                      config.apiAmoresens + "/seguimientopedido/estados", 
                    {
                      'headers': { 'token': this.$cookie.get('token') },
                      params: {m_inout_id : entregas.m_inout_id}
                    }).then(res=>{return res.data;})
                    .catch(err=>{console.log(err);return false;});    

                    if (estadosres.status == "success") {
                      this.purchases[index].entregas[i].estados = estadosres.data;  
                    }else{
                      this.purchases[index].entregas[i].estados = [];
                    } 
                  }
                  // :::::::::::::::::::::::::: get estados 
                }else{
                  this.purchases[index].entregas = [];
                }
              } 

            } 
        }else if(this.user.status == "unauthorized"){  
            this.menu('/shop/Login/');
        }
    },
    modalComentario(_id,documentno,ad_user_id,nombre_cliente){
      // console.log(_id,documentno,ad_user_id,nombre_cliente);
      this.comentario.ad_user_id = ad_user_id;
      this.comentario.nombre_cliente = nombre_cliente;
      this.comentario.documentno = documentno;
      this.opinion = true
    },
    async enviarOpinion(){
      await axios.post(config.apiAmoresens + '/comentario/agregarComentario',
      this.comentario,{'headers': { 'token': this.$cookie.get('token') }})
      .then(res=>{
        this.comentarioRes = res.data;
      }).catch(err=>{
        return err
      })
      if(this.comentarioRes == false){
        this.msgError="Ocurrio un error, favor de intentarlo más tarde"
      }else{
        this.comentario.ad_user_id = '';
        this.comentario.nombre_cliente = '';
        this.comentario.documentno = '';
        this.opinion = false;
        this.alert2=true;
        this.msgError="¡ Gracias por tu comentario ! "
        window.scrollTo(0,0);
        // party.confetti(element, {
        //     shapes: ["square", "circle", "roundedRectangle"]
        //   });
      }
    }
  }, 
  computed:{ 
    purchasesLength(){
          return this.purchases.length;
    },  
    items(){
      return this.productosPaginator.filter(item=>{
        return item.documentno.toLowerCase().includes(this.buscar.toLowerCase()) ||
                item.direccionTienda.toLowerCase().includes(this.buscar.toLowerCase());
      })
    },
    //>>>>>>>>> Filtador
    filteredList() {
      return this.productosPaginator.filter(post => {
        return post.documentno.toLowerCase().includes(this.buscar.toLowerCase())
        // post.status_pay.toLowerCase().includes(this.buscar.toLowerCase())
      })
    }
  },  
}
</script>
